import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile-card.component.html',
  styleUrl: './profile-card.component.scss',
})
export class ProfileCardComponent implements OnInit {
  @Input() fullName: string | undefined = '';
  @Input() imageUrl: string | null | undefined = null;
  @Input({ required: true }) width = '';
  @Input({ required: true }) height = '';
  @Input() classes = '';
  @Input() radiusClass = 'rounded-circle';

  initials = '';
  // backgroundColors = [
  //   { id: 1, color: 'bg-info text-white' },
  //   { id: 2, color: 'bg-primary border border-white p-3 text-white' },
  //   { id: 3, color: 'bg-primary-2 text-white' },
  //   { id: 4, color: 'bg-primary-3 text-white' },
  //   { id: 5, color: 'bg-secondary text-secondary' },
  //   { id: 6, color: 'bg-warning text-white' },
  //   { id: 7, color: 'bg-success text-white' },
  // ];
  // backgroundColor = '';
  // userColor = '';
  // ngClasses = '';

  ngOnInit(): void {
    if (!this.imageUrl) {
      this.calculateInitials();
    }
  }

  calculateInitials() {
    if (this.fullName) {
      const names = this.fullName.split(' ');
      this.initials = names
        .map(name => name[0])
        .join('')
        .toUpperCase();
      // this.userColor = this.getRandomBackgroundColor();
    }
  }

  // getRandomBackgroundColor() {
  //   this.ngClasses = '';
  //   if (!this.imageUrl) {
  //     let index = 0;
  //     index = Math.floor(Math.random() * this.backgroundColors.length);
  //     this.backgroundColor = this.backgroundColors[index].color;
  //     this.ngClasses = this.backgroundColor + ' p-2';
  //   }
  //   return this.ngClasses;
  // }
}
