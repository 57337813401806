<!-- TODO :: REPLACE ACTUAL NGCLASS BY THIS ON RANDOW COLORS FEATURE IS STABLE -->
<!-- [ngClass]="userColor" -->

<div
  class="d-flex justify-content-center {{ classes }} align-items-center {{
    radiusClass
  }} text-all-white"
  [ngClass]="{
    'p-2 bg-info': !imageUrl,
  }"
  [style.width]="width"
  [style.height]="height">
  @if (imageUrl) {
    <img
      [src]="imageUrl"
      alt=""
      class="w-100 h-100  {{ radiusClass }}"
      style="object-fit: cover" />
  } @else {
    <span class="fw-bold text-center">{{ initials }}</span>
  }
</div>
